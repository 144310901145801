import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
    return (
        <footer className="footer">
            <nav>
                <ul className="footer-nav">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                </ul>
            </nav>
        </footer>
    );
}

export default Footer;