import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './pages/homePage';
import Projects from './pages/Projects';
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

function RedirectToHome() {
  let navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
}

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/home" element={<RedirectToHome />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
