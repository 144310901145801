import React, { useEffect } from 'react';
import ProjectComponent from '../Components/projectComponent';
import AboutMeComponent from '../Components/aboutMe';



export default function Home() {

    useEffect(() => {

        //Intersection Observer//
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                console.log(entry)
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                }
            });
        });

        const hiddenElements = document.querySelectorAll('.hidden, .hidden1, .hidden2');
        hiddenElements.forEach((el) => observer.observe(el));


        //Scroll effect//


        //Typing effect//

        const text = document.querySelector('h1');

        if (!text) return;

        const strText = text.textContent;
        const splitText = strText.split("");
        text.textContent = "";

        for (let i = 0; i < splitText.length; i++) {
            text.innerHTML += "<span>" + splitText[i] + "</span>";
        }

        let char = 0;
        const timer = setInterval(onTick, 60);

        function onTick() {
            const span = text.querySelectorAll('span')[char];
            span.classList.add('fade');
            char++;
            if (char === splitText.length) {
                complete();
            }
        }

        function complete() {
            clearInterval(timer);
        }

        return () => clearInterval(timer);

    }, []);

    return (
        <>
            <section className="homebox">


                <div className="herobox">

                    <div className="Welcometxt">
                        <h1>Evan Long</h1>
                    </div>
                    <div className='HSAbox hidden'>
                        <p className='hsatxt'>B.S. Healthservices Administration</p>
                    </div>


                </div>

                <svg className="arrows">
                    <path className="a1" d="M0 0 L30 32 L60 0"></path>
                    <path className="a2" d="M0 20 L30 52 L60 20"></path>
                    <path className="a3" d="M0 40 L30 72 L60 40"></path>
                </svg>

            </section>

            <section className="about-section-parent">
                <h1 className="heads hidden1">About Me</h1>
                <AboutMeComponent />
            </section>

            <section>
                <ProjectComponent />
            </section>



        </>
    )
}
