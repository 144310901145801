import React from 'react';
import { Link } from 'react-router-dom';

export default function ProjectComponent() {

    return (
        <>

            <section className="home-projects">
                <Link to="/projects" className="project-link5">
                    View Projects
                </Link>



            </section>
        </>
    )
}


